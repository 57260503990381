export const environment = {
  production: true,

  apid: "http://localhost:3001/aXRvYW0=/api",
  apiAuthd: "http://localhost:3001/aXRvYW0=",

  api: "https://api.easytecsis.com.br/aXRvYW0=/api",
  apiAuth: "https://api.easytecsis.com.br/aXRvYW0=",

  viaCep: "//viacep.com.br/ws",
};
